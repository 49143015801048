import React from 'react'
import Layout from '../../../../components/EN/LayoutEN'
import ServiceHeader from '../../../../components/Services/ServiceOverview/ServiceHeader'
import '../../../../components/ParagraphUnderPhoto/styles.css'
import './styles.css'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Image from '../../../../components/Image/Image'

export const Dane = ({
  data: { page, image1, image2, image3, yellowArrow },
}) => {
  const { frontmatter } = page

  return (
    <>
      <Layout
        meta={page.frontmatter.meta || false}
        title={page.frontmatter.title || false}
      >
        <ServiceHeader
          title={frontmatter.title}
          backgroundImage={
            !!frontmatter.image.childImageSharp
              ? frontmatter.image.childImageSharp.fluid.src
              : frontmatter.image
          }
        />
        <div className="container">
          <div className="PageWrapper row justify-content-md-center align-items-center">
            <div className="col-md-6">
              {!!image1 && !!image1.childImageSharp ? (
                <Img
                  fluid={image1.childImageSharp.fluid}
                  alt="pripadove-studie-1"
                />
              ) : (
                <img src={image1.src} alt="pripadove-studie-1" />
              )}
            </div>
            <div className="col-md-6">
              <div className="BlueBox">
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>VAT registration of few hundreds of tax entities</p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Implementation of so called “call-off-stock” warehouses
                    system for dozens of suppliers of a large retail chain
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Tax solution of business restructuring of an important sport
                    facility
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Suggestion of many business transformation processes and
                    assistance with the implementation
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Transfer pricing study cases for multinational groups and
                    the defence at Tax Authority
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="PageWrapper row justify-content-md-center align-items-center">
            <div className="col-md-6">
              <div className="BlueBox">
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>Sale of an important educational facility</p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Successful representation in an appeal against the results
                    of tax inspection of the “deemed employment” at a
                    manufacturing company
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Implementation of online accounting for trading or
                    manufacturing company with dozens of employees
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Representation in a dispute with the Financial
                    Administration over VAT in the amount of more than 30 mil.
                    CZK (i.e. approx. 1,1 mil. €) in the Czech Republic
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {!!image2 && !!image2.childImageSharp ? (
                <Img
                  fluid={image2.childImageSharp.fluid}
                  alt="pripadove-studie-2"
                />
              ) : (
                <img src={image2.src} alt="pripadove-studie-2" />
              )}
            </div>
          </div>
          <div className="PageWrapper row justify-content-md-center align-items-center">
            <div className="col-md-6">
              {!!image3 && !!image3.childImageSharp ? (
                <Img
                  fluid={image3.childImageSharp.fluid}
                  alt="pripadove-studie-3"
                />
              ) : (
                <img src={image3.src} alt="pripadove-studie-3" />
              )}
            </div>
            <div className="col-md-6">
              <div className="BlueBox">
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Restructuring of the production plant, de-merger into
                    several divisions and assistance with the sale of key assets
                    to the new owner
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Assistance with the sale of small hydroelectric power plant
                    in the CR
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>• Due diligence in selling or purchasing companies </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Assistance with the implementation of ERP system in a
                    manufacturing company with a turnover of billions CZK
                  </p>
                </div>
                <div className="OneLine">
                  <Image
                    src={yellowArrow.childImageSharp.fluid.src}
                    alt="arrow"
                  />
                  <p>
                    Both domestic and international transactions structuring –
                    design and implementation of acquisition structures,
                    liquidation of off-shore structures
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default Dane

export const pageQuery = graphql`
  query PripadoveStudieEN {
    page: markdownRemark(
      frontmatter: { slug: { eq: "en/about-us/case-studies" } }
    ) {
      ...Meta
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    image1: file(relativePath: { eq: "pripadove-studie1.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "pripadove-studie2.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "pripadove-studie3.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yellowArrow: file(relativePath: { eq: "yellow-arrow.png" }) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
  }
`
